const ensureTrailingSlash = path => {
  if (path) {
    if (!path.endsWith("/")) {
      path += "/";
    }
  }
  return path;
};

export default ensureTrailingSlash;
